.numbers {
    background-color: #3d3d3d;

    position: relative;

    &::before {
        content: '';

        @include adaptive("width", 313, 113);
        @include adaptive("height", 313, 113);
        border: 20px solid #dcdcdc;
        opacity: 0.11;
        border-radius: 50%;

        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-60%, -50%);

        @media(max-width:900px) {
            border: 10px solid #dcdcdc;
        }
    }
}

.numbers__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 208px 0 56px;
    @include adaptive("padding-top", 208, 30);
    @include adaptive("padding-bottom", 56, 20);
}

.numbers__title {
    @include adaptive(" margin-bottom", 30, 15);
}

.numbers__dots {
    @include adaptive(" margin-bottom", 41, 29);
}

.numbers__list {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media(max-width:767px) {
        flex-direction: column;
        align-items: center;
    }
}

.numbers__item {
    &+& {
        @media(max-width:767px) {
            margin-top: 20px;
        }
    }
}

.number {
    color: #ffffff;
    font-family: "Gilroy-Heavy";

    @include adaptive("font-size", 60, 30);
    @include adaptive("line-height", 48, 24);

    margin-bottom: 22px;

    position: relative;
    z-index: 2;

    &::before {
        content: attr(data-letters);

        font-family: "Gilroy-Heavy";
        @include adaptive("font-size", 60, 30);
        @include adaptive("line-height", 48, 24);
        color: #636262;

        position: absolute;
        top: 4px;
        left: 4px;
        z-index: -1;

        @media(max-width:1024px) {
            top: 3px;
            left: 3px;
        }
    }

    @media(max-width:767px) {
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
    }
}

.numbers__descr {
    max-width: 180px;

    color: #c4c4c4;
    font-family: "Gilroy-Heavy";
    font-size: 16px;
    line-height: 22px;
    @include adaptive("font-size", 16, 14);
    @include adaptive("line-height", 22, 14);

    @media(max-width:1023px) {
        max-width: 140px;
    }

    @media(max-width:768px) {
        max-width: 120px;
    }

    @media(max-width:767px) {
        max-width: unset;
        text-align: center;
    }
}