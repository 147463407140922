.select {
    width: calc(50% - 9px);
    position: relative;
    &::before {
        content: "";
        width: 14px;
        height: 10px;
        background-image: url(../img/select-arrow.svg);
        position: absolute;
        right: 25px;
        top: calc(50% - 5px);
        z-index: 2;
        transition: all 0.3s linear 0s;
        pointer-events: none;
    }
    select {
        display: flex;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 50px 0 30px;
        box-shadow: 0 18px 24px rgb(118 118 118 / 14%);
        border-radius: 30px;
        border: 1px solid #e8e8e8;
        background-color: #ffffff;
        color: #797979;
        font-family: Gilroy;
        letter-spacing: 0.38px;
        font-size: calc(13px + 2 * ((100vw - 320px) / 1600));
        cursor: pointer;
    }
}