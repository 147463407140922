.formats {
    background-image: linear-gradient(to top, #3d3d3d 58%, #3d3d3d 84%, rgba(#1b1b1b, .9) 100%);

    @include adaptive("padding-top", 99, 20);
    @include adaptive("padding-bottom", 123, 40);

    position: relative;

    &::before {
        content: '';
        @include adaptive("width", 248, 80);
        @include adaptive("height", 248, 80);
        border: 20px solid #dcdcdc;
        opacity: 0.11;
        border-radius: 50%;

        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);

        @media(max-width:900px) {
            border: 10px solid #dcdcdc;
        }
    }

    &::after {
        content: '';
        @include adaptive("width", 313, 113);
        @include adaptive("height", 313, 113);
        border: 20px solid #dcdcdc;
        opacity: 0.13;
        border-radius: 50%;

        position: absolute;
        right: 0;
        bottom: 119px;
        transform: translateX(50%);

        @media(max-width:900px) {
            border: 10px solid #dcdcdc;
        }
    }
}

.formats__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formats__title {
    @include adaptive("margin-bottom", 30, 15);
}

.formats__dots {
    @include adaptive("margin-bottom", 34, 20);
}

.formats__item {
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(2n+2) {
        flex-direction: row-reverse;

        .formats__arrow {
            transform: scale(-1, 1);
        }
    }

    @media(max-width:600px) {
        margin-bottom: 20px;
    }
}

.formats__img {
    @include adaptive("width", 370, 75);
    @include adaptive("height", 367, 75);
    object-fit: contain;
}

.formats__arrow {
    display: inline-block;

    @include adaptive("width", 49, 15);
    @include adaptive("height", 89, 25);
    @include adaptive("margin-right", 50, 10);
    @include adaptive("margin-left", 50, 10);
}

.formats__name {
    @include adaptive("margin-bottom", 34, 10);

    color: #ffffff;
    font-family: "Gilroy-Heavy";
    @include adaptive("font-size", 20, 16);

}

.formats__text {
    max-width: 604px;

    color: #c4c4c4;
    font-family: Gilroy;
    @include adaptive("font-size", 16, 14);
    @include adaptive("line-height", 28, 16);
}