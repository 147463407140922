.contacts {
    @include adaptive("padding-top", 130, 35);
    @include adaptive("padding-bottom", 130, 35);

    background-size: cover;
    background-repeat: no-repeat;

    position: relative;
}

.contacts__inner {
    display: flex;
    align-items: center;

    @media(max-width:1023px) {
        flex-direction: column;
    }
}

.contacts__info {
    margin-right: 38px;

    @media(max-width:1439px) {
        margin-right: 25px;

    }
}

.contacts__title {
    @include adaptive("margin-bottom", 44, 25);

    @media(max-width:1023px) {
        text-align: center;
    }
}

.contacts__dots {
    @include adaptive("margin-bottom", 68, 28);

    @media(max-width:1023px) {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}


.contacts__item {
    padding-left: 75px;
    margin-bottom: 30px;
    position: relative;

    @media(max-width:1440px) {
        padding-left: 0;
    }

    @media(max-width:1023px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.contacts__img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media(max-width:1440px) {
        position: static;
        transform: translateY(0);
        margin-bottom: 10px;
    }

    @media(max-width:549px) {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }
}

.contacts__name {
    margin-bottom: 12px;

    color: #02a9e7;
    font-family: Gilroy;
    @include adaptive("font-size", 16, 14);

    @media(max-width:549px) {
        margin-bottom: 8px;

    }
}

.contacts__link {
    color: #c4c4c4;
    font-family: "Gilroy-Black";
    @include adaptive("font-size", 20, 16);

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        height: 1px;
        width: 0;
        background-color: #c4c4c4;

        position: absolute;

        right: 0;
        left: auto;
        bottom: -2px;

        transition: .2s linear;
    }

    &:hover {
        color: #c4c4c4;
    }

    &:hover::before {
        @media(min-width:1024px) {
            width: 100%;
            left: 0;
            right: auto;
        }
    }
}

.form {
    width: 100%;
    max-width: 870px;
    padding: 58px 69px;

    border-radius: 30px;
    background-color: #ffffff;

    position: relative;

    @media(max-width:1439px) {
        padding: 25px 25px;
        max-width: 630px;
    }

    @media(max-width:1023px) {
        padding: 15px 15px;
    }

}

.form__title {
    @include adaptive("margin-bottom", 25, 15);

    color: #2e2e2e;
    font-family: "Gilroy-Black";
    @include adaptive("font-size", 28, 16);
}

.form__text {
    max-width: 451px;
    @include adaptive("margin-bottom", 31, 20);

    font-family: "Gilroy";
    color: #2e2e2e;
    @include adaptive("font-size", 16, 14);
}

.form__blocks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 530px;
    margin-bottom: 34px;

    position: relative;
    z-index: 2;

    @media(max-width:1439px) {
        max-width: 485px;
        margin-bottom: 24px;
    }

    @media(max-width:1023px) {
        margin-bottom: 14px;
    }

    @media(max-width:549px) {
        flex-direction: column;
        align-items: center;
    }
}

.form__label {
    width: calc(50% - 9px);
    margin-bottom: 15px;

    position: relative;

    @media(max-width:767px) {
        width: calc(50% - 5px);
        margin-bottom: 10px;
    }

    @media(max-width:549px) {
        width: 100%;
    }

    &::before {
        content: '';
        background-size: cover;
        background-repeat: no-repeat;

        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
    }

    &.name::before {
        width: 16px;
        height: 17px;
        background: url('../img/icon1.png');
    }

    &.mail::before {
        width: 22px;
        height: 17px;
        background: url('../img/icon2.png');
    }

    &.skype::before {
        width: 18px;
        height: 18px;
        background: url('../img/icon3.png');
    }

    &.telegram::before {
        width: 20px;
        height: 18px;
        background: url('../img/icon4.png');
    }
}

.form__input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0 50px 0 30px;

    box-shadow: 0 18px 24px rgba(118, 118, 118, 0.14);
    border-radius: 30px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;

    color: #797979;
    font-family: Gilroy;
    letter-spacing: 0.38px;

    @include adaptive("font-size", 15, 13);

    @media(max-width:768px) {
        height: 50px;
    }
}

.form__btn {
    @media(max-width:549px) {
        display: block;
        margin: 0 auto;
    }
}

.form__img {
    width: 298px;
    height: 513px;

    position: absolute;
    bottom: 0;
    right: -9px;

    @media(max-width:1439px) {
        width: 248px;
        height: 433px;
    }

    @media(max-width:767px) {
        display: none;
    }
}