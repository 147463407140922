// базовые подключения
@import "vars";
@import "mixins";
@import "fonts";
@import "null";
@import "global";

// подключения компонентов страницы
/* @import "./components/header"; */

@import "./components/intro";
@import "./components/btn";
@import "./components/numbers";
@import "./components/info";
@import "./components/formats";
@import "./components/contacts";
@import "./components/select";
@import "./components/captcha";
@import "./components/footer";