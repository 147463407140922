.info {
    background-image: linear-gradient(to top, #3d3d3d 58%, #3d3d3d 84%, #313131 100%);

    @include adaptive("padding-top", 105, 35);
    @include adaptive("padding-bottom", 105, 35);

    position: relative;

    &::before {
        content: '';
        @include adaptive("width", 313, 113);
        @include adaptive("height", 313, 113);
        border: 20px solid #dcdcdc;
        opacity: 0.35;
        border-radius: 50%;

        position: absolute;
        right: 0;
        bottom: -222px;
        transform: translateX(50%);
        z-index: 2;

        @media(max-width:900px) {
            border: 10px solid #dcdcdc;
        }
    }
}

.info__inner {
    display: flex;
    justify-content: space-between;

    @media(max-width:767px) {
        flex-direction: column;
    }
}

.info__item {
    width: calc(50% - 15px);
    padding: 39px 70px 53px;

    box-shadow: 0 18px 24px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    background-color: #636262;

    @media(max-width:1024px) {
        padding: 29px 40px 33px;
    }

    @media(max-width:767px) {
        width: 100%;
        padding: 19px 20px 20px;

        &+& {
            margin-top: 25px;
        }
    }
}

.info__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include adaptive("margin-bottom", 34, 24);
}

.info__text {
    margin-bottom: 46px;

    color: #ffffff;
    font-family: Gilroy;

    @include adaptive("font-size", 20, 16);
}

.info__list {
    @include adaptive("margin-bottom", 44, 24);
}

.info__list-item {
    display: flex;

    &+& {
        @include adaptive("margin-top", 31, 15);

    }
}

.info__list-number {
    display: flex;
    justify-content: center;
    align-items: center;


    font-weight: 700;
    color: #ffffff;
    font-family: Gilroy;
    font-size: 13px;
    line-height: 1.1;

    position: relative;
    z-index: 1;

    &::before {
        content: '';

        width: 16px;
        height: 16px;
        border-radius: 50%;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    &.blue::before {
        background-color: #02a9e7;
    }

    &.red::before {
        background-color: #fb5b5b;
    }
}

.info__list-text {
    margin-left: 14px;
}