.btn {
    font-family: "Gilroy-Black";
    font-size: 13px;
    text-transform: uppercase;
    color: #ffffff;

    transition: .2s linear;
    will-change: transform;

    @media(max-width:768px) {
        font-size: 11px;
    }

    &--white {
        padding: 19px 25px;

        border-radius: 25px;
        border: 1px solid #ffffff;

        position: relative;
        z-index: 2;
        overflow: hidden;

        @media(max-width:768px) {
            padding: 14px 20px;
        }

        &::before {
            content: '';
            width: 160px;
            height: 160px;
            border-radius: 50%;
            background-color: #ffffff;

            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 20%);

            transition: .2s linear;

            @media(max-width:1023px) {
                display: none;
            }
        }

        &:hover {
            @media(min-width:1024px) {
                color: #000;
            }
        }

        &:hover::before {
            @media(min-width:1024px) {
                transform: translate(-50%, -50%);
            }
        }
    }

    &--blue {
        padding: 24px 40px;

        border-radius: 30px;
        box-shadow: 0 11px 16px 2px rgba(0, 80, 111, 0.34);
        background-image: linear-gradient(to top, #0179d5 0%, #03abea 100%);

        text-shadow: 0 1px 1px rgba(40, 21, 21, 0.55);

        @media(max-width:768px) {
            padding: 19px 35px;
        }

        &:hover {
            @media(min-width:1024px) {
                transform: scale(1.07);
            }
        }
    }

    &--red {
        padding: 24px 40px;

        border-radius: 30px;

        box-shadow: 0 11px 16px 2px rgba(111, 0, 0, 0.34);
        background-image: linear-gradient(to top, #ba2e2e 0%, #fb5c5c 100%);

        text-shadow: 0 1px 1px rgba(40, 21, 21, 0.55);

        @media(max-width:768px) {
            padding: 19px 35px;
        }

        &:hover {
            @media(min-width:1024px) {
                transform: scale(1.07);
            }
        }
    }
}

.btn-top {
    width: 60px;
    height: 60px;

    background-image: url('../img/btn-top.png');
    background-size: cover;
    position: absolute;
    top: 290px;
    right: 144px;

    transition: .2s linear;
    will-change: transform;

    @media(max-width:1600px) {
        right: 40px;
    }

    @media(max-width:1023px) {
        width: 40px;
        height: 40px;
    }

    @media(max-width:549px) {
        top: 160px;
        right: 10px;
    }

    &::before {
        content: '';
        width: 60px;
        height: 200px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &:hover {
        @media(min-width:1024px) {
            transform: scale(1.07);
        }
    }
}

.btn-top__text {
    color: #c4c4c4;
    font-family: "Gilroy-Black";
    font-size: 16px;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    white-space: nowrap;

    position: absolute;
    top: 100%;
    right: -50%;
    transform: rotate(-90deg) translate(-50%, 45%);

    @media(max-width:1023px) {
        font-size: 14px;
        transform: rotate(-90deg) translate(-42%, 78%);
    }
}