.footer {
    background-color: #3d3d3d;
    height: 76px;

    position: relative;

    @media(max-width:1024px) {
        height: 50px;
    }

    @media(max-width:767px) {
        height: auto;
        padding: 15px 0;
    }

    &::before {
        content: '';

        @include adaptive("width", 248, 80);
        @include adaptive("height", 248, 80);
        border: 20px solid #dcdcdc;
        opacity: 0.11;
        border-radius: 50%;

        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-40%, 40%);

        @media(max-width:900px) {
            border: 10px solid #dcdcdc;
        }
    }
}

.footer__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media(max-width:767px) {
        flex-direction: column;
    }
}

.footer__link {
    color: #ffffff;
    font-family: Gilroy;
    @include adaptive("font-size", 15, 12);

    position: relative;

    &::before {
        content: '';
        height: 1px;
        width: 100%;
        background-color: #ffffff;

        position: absolute;
        left: 0;
        right: auto;
        bottom: -1px;

        transition: .2s linear;
    }

    &:hover {
        color: #ffffff;
    }

    &:hover::before {
        width: 0;

        left: auto;
        right: 0;
    }
}

.footer__copyright {
    color: #c4c4c4;
    font-family: Gilroy;
    @include adaptive("font-size", 15, 12);

    @media(max-width:767px) {
        margin: 20px auto;
    }
}