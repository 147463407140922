.captcha {
    width: calc(50% - 9px);
    height: 60px;

    box-shadow: 0 18px 24px rgba(118, 118, 118, 0.14);
    border-radius: 30px;
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    
    text-align: center;
}