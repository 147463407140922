* {
    font-family: "GothamPro", sans-serif;
}

body {
    background: #F4F9FE;

    &.scroll-hide {
        overflow: hidden;
    }
}

.container {
    max-width: 1260px;
    margin: 0 auto;
    padding: 0 30px;

    @media(max-width:768px) {
        padding: 0 15px;

    }
}

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.title {
    color: #ffffff;
    font-family: "Gilroy-Heavy";
    @include adaptive("font-size", 36, 24);
    @include adaptive("line-height", 48, 30);
}