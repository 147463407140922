.intro {
    background-size: cover;
    background-repeat: no-repeat;
}

.intro__inner {
    position: relative;
    // padding-bottom: px;

    @include adaptive("padding-bottom", 114, 40);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include adaptive("padding-top", 43, 15);
    // margin-bottom: 119px;
    @include adaptive("margin-bottom", 119, 55);
}

.header__logo {
    width: 159px;
    height: 28px;

    img {
        width: 100%;
        height: auto;
        object-fit: cover
    }

    @media(max-width:768px) {
        width: 125px;
        height: 23px;
    }
}

.intro__title {
    @include adaptive("max-width", 810, 273);
    @include adaptive("margin-bottom", 61, 30);
    // margin-bottom: 61px;

    color: #ffffff;
    font-family: "Gilroy-Heavy";
    @include adaptive("font-size", 36, 24);
    @include adaptive("line-height", 48, 30);

    span {
        font-family: "Gilroy-Heavy";
        @include adaptive("font-size", 36, 24);
        @include adaptive("line-height", 48, 30);
        color: #02a9e7;
    }

    @media(max-width:650px) {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.intro__descr {
    max-width: 270px;

    color: #c4c4c4;
    font-family: 'Gilroy';
    @include adaptive("font-size", 16, 14);
    @include adaptive("line-height", 27, 21);
    @include adaptive("margin-bottom", 50, 30);

    @media(max-width:650px) {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.intro-dots {
    display: block;
    margin-left: 87px;
    @include adaptive("margin-bottom", 41, 30);

    @media(max-width:650px) {
        margin-left: auto;
        margin-right: auto;
    }
}

.intro__img-pc {
    width: 932px;
    height: 574px;

    position: absolute;
    bottom: -140px;
    right: -80px;
    z-index: 2;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media(max-width:1440px) {
        width: 832px;
        height: 514px;
        bottom: -77px;
        right: 0;
    }

    @media(max-width:1124px) {
        width: 691px;
        height: 434px;
    }

    @media(max-width:930px) {
        width: 521px;
        height: 314px;

        bottom: 54px;
        right: 22px;
    }

    @media(max-width:790px) {
        width: 441px;
        height: 274px;
    }

    @media(max-width:680px) {
        display: none;
    }
}

.intro__btn {
    @media(max-width:650px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}